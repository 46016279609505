export const environment = {
  production: true,
  development: false,
  isApplicationUnderConstruction: false,
  api: 'https://api-sso.amica.pl',
  upload: 'https://api-sso.amica.pl/uploads',
  // amica.pl
  // defaultAppClientId: '115a0b27db5ca7e8c421a46015d516f6',
  amicaAppClientId: '115a0b27db5ca7e8c421a46015d516f6',
  amicaUrl: 'https://www.amica.pl',
  mojaAmicaAppClientId: '96a08901a182775a712e96ed85363fc7',
  mojaAmicaUrl: 'https://moja.amica.pl',
  mojaAmicaLocalHostAddress: '',
  recaptchaV3SiteKey: '6LfI-RMiAAAAAJ71UXwAJxTYJjE1NYO7cAUjBqm-',
  googleClientId: '1066867851821-sqiib0tukv044cjrvdnl7505ejftr0c1.apps.googleusercontent.com',
  facebookClientId: '4807266469343163',
  syneriseKey: 'b92a519a-efe9-4695-9b68-4d4a72680815',
};
